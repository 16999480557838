import React from 'react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash';
import {
    Image,
    Indicator,
    DynamicText,
    Video,
    Button,
    InitialNavigation,
    SpecialText,
    Sentence,
    Audio,
    Navigate,
    Special,
    LoadChapter
} from '../../components'
import styles from '../animation2/animation2.module.css'
import useAppContext from '../../context/Context'
import {useIntl} from 'react-intl'
import '../../styles/animation.css'
import { MESSAGES, TOTAL, MESSAGES_COUNTER, MESSAGES_VIDEOS, SENTENCE, MESSAGES_VIDEOS_ANNEXED } from '../../constants/chapter-3'

const Animation3 = () => {
    const customDiapositives = {
        refer: React.createRef(null),
        matchesIndicatorsAdd: [31, 35],
        matchesIndicatorsRemove: [32, 36]
    }
    const [percentLoad, setPercentLoad] = React.useState(100);
    const navigate = useNavigate();
    const {
        indicator,
        handleIndicator,
        addClasses,
        showMessage,
        setShowMessage,
        handleNavigate,
        resetInit,
        pauseAllMedia,
        setPauseAllMedia,
        setHiddePercentLoad,
        hiddePercentLoad,
        handleTouchStart,
        handleTouchMove,
        handleTouchEnd,
    } = useAppContext()
    const intl = useIntl()

    React.useEffect(resetInit, []);


    const showNextDiapo = [1, 3, 6, 11, 14, 18, 19, 23, 24, 26, 32, 36, 37, 38, 40];

    const handleScroll = (e) => {
        e.deltaY > 20 && toggleIndicator("add");
        e.deltaY < -20 && toggleIndicator("remove");
    }

    const pressFunction = (e) => {
        switch (e.keyCode) {
            case 32: setPauseAllMedia(!pauseAllMedia);
            break;
            case 40: toggleIndicator("add");
            break;
            case 38: toggleIndicator("remove")
            break;
        }
    }


    React.useEffect(() => handleNavigate(showNextDiapo, 3),[indicator, hiddePercentLoad])

    React.useEffect(()=>{
        customDiapositives.refer.current.classList.add("first-animation")
    },[])

    const changeWithClick = () => {
        toggleIndicator("add");
    }

    const toggleIndicator = (operation) => {
        let quantity = 1;
        switch (operation) {
            case "add": indicator === 13 && (quantity = 14);
            break;
            case "remove": indicator === 27 && (quantity = 14);
            break;
            default: {};
        }

        handleIndicator[operation](customDiapositives, TOTAL, quantity);
    }

    React.useEffect(() => {
        let timer = setTimeout(() =>{
            if (indicator === 11) {
                toggleIndicator("add");
            }
          },5000)
          return () => {
              clearInterval(timer);
          };
    },[indicator])

    const handleFinal = (redirect) => {
        navigate(redirect);
    }

    const loadCount = React.useRef(0);

    const setVideoLoaded = async() => {
        if (loadCount.current < 29) {
            loadCount.current = loadCount.current + 1;
            setPercentLoad(
                Math.round((loadCount.current * 100) / 29)
            )
        } else {
            console.log("All videos loaded!");
        }
    };

    const handleLoadResources = () => {
        setHiddePercentLoad(true)
        const box = document.getElementById('fadrines-animation-3');
        box.tabIndex = '-1';
        box.focus();
    }

    const handleAnnexed = () => {
        handleIndicator.add(customDiapositives, TOTAL, 1);

        const box = document.getElementById('fadrines-animation-3');
        box.tabIndex = '-1';
        box.focus();
    }

    return(
        <>
            <LoadChapter
                percent={percentLoad}
                handleClick={handleLoadResources}
                hiddePercent={hiddePercentLoad}
                imageUrl="/images/CAP_03/C3_29.jpg"
            />
            <div id='fadrines-animation-3' className={'relative full-window-w full-window-h'} onWheel={_.debounce(handleScroll,500)} onKeyDown={_.debounce(pressFunction,500)} tabIndex={-1}>
                <div ref={customDiapositives.refer} className={`absolute full-w full-h ${styles.transition} ${addClasses ? styles.transitionActive : ''}`} />
                <div className={`relative full-window-w full-window-h grid ${styles.images}`}>
                    {/* Content Start */}
                    <Image
                                callback={changeWithClick}
                                customClasses={` absolute-content ${(indicator===1 && hiddePercentLoad) ? ` content-active` : ` content-disabled`}`}
                                uri="/images/CAP_03/C3_00.jpg"
                            />
                            <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                                callback={changeWithClick}
                                showControls={true}
                                index={2}
                                customClasses={`absolute-content ${indicator=== 2 ? `content-active` : `content-disabled`}`}
                                uri={"/VIDEOS_C3/C3_01.mp4"}
                                subtitles={[
                                    '/VIDEOS_C3/C3_01_SRT_catalan.vtt',
                                    '/VIDEOS_C3/C3_01_SRT_espanol.vtt',
                                    '/VIDEOS_C3/C3_01_SRT_ingles.vtt'
                                ]}
                            />
                            <Image
                                callback={changeWithClick}
                                customClasses={`absolute-content  ${indicator===3 ? `content-active` : ` content-disabled`}`}
                                uri="/images/CAP_03/C3_02.jpg"
                            />
                            <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                                callback={changeWithClick}
                                showControls={true}
                                index={4}
                                customClasses={`absolute-content ${indicator=== 4 ? `content-active` : `content-disabled`}`}
                                uri={"/VIDEOS_C3/C3_03.mp4"}
                                subtitles={[
                                    '/VIDEOS_C3/C3_03_SRT_catalan.vtt',
                                    '/VIDEOS_C3/C3_03_SRT_espanol.vtt',
                                    '/VIDEOS_C3/C3_03_SRT_ingles.vtt'
                                ]}
                            />
                            <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                                callback={changeWithClick}
                                showControls={true}
                                index={5}
                                customClasses={`absolute-content ${indicator=== 5 ? `content-active` : `content-disabled`}`}
                                uri={"/VIDEOS_C3/C3_04.mp4"}
                                subtitles={[
                                    '/VIDEOS_C3/C3_04_SRT_catalan.vtt',
                                    '/VIDEOS_C3/C3_04_SRT_espanol.vtt',
                                    '/VIDEOS_C3/C3_04_SRT_ingles.vtt'
                                ]}
                            />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={false}
                            index={6}
                            customClasses={`absolute-content ${indicator=== 6 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/C3_05.mp4"}
                            subtitles={['','','']}
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={7}
                            customClasses={`absolute-content ${indicator=== 7 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/C3_06.mp4"}
                            subtitles={[
                                '/VIDEOS_C3/C3_06_SRT_catalan.vtt',
                                '/VIDEOS_C3/C3_06_SRT_espanol.vtt',
                                '/VIDEOS_C3/C3_06_SRT_ingles.vtt'
                            ]}
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={8}
                            text={MESSAGES_VIDEOS[8]}
                            customClasses={`absolute-content ${indicator=== 8 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/C3_07.mp4"}
                            subtitles={[
                                '/VIDEOS_C3/C3_07_SRT_catalan.vtt',
                                '/VIDEOS_C3/C3_07_SRT_espanol.vtt',
                                '/VIDEOS_C3/C3_07_SRT_ingles.vtt'
                            ]}
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={9}
                            customClasses={`absolute-content ${indicator=== 9 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/C3_08.mp4"}
                            subtitles={['','','']}
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={10}
                            customClasses={`absolute-content ${indicator=== 10 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/C3_09.mp4"}
                            subtitles={[
                                '/VIDEOS_C3/C3_09_SRT_catalan.vtt',
                                '/VIDEOS_C3/C3_09_SRT_espanol.vtt',
                                '/VIDEOS_C3/C3_09_SRT_ingles.vtt'
                            ]}
                        />
                        <Image
                            callback={changeWithClick}
                            customClasses={`absolute-content ${indicator === 11 ? ` content-active` : ` content-disabled`}`}
                            uri="/images/CAP_03/c3_10.jpg"
                        />
                            <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                                callback={changeWithClick}
                                showControls={true}
                                index={12}
                                customClasses={`absolute-content ${indicator=== 12 ? `content-active` : `content-disabled`}`}
                                uri={"/VIDEOS_C3/C3_11.mp4"}
                                subtitles={[
                                    '/VIDEOS_C3/C3_11_SRT_catalan.vtt',
                                    '/VIDEOS_C3/C3_11_SRT_espanol.vtt',
                                    '/VIDEOS_C3/C3_11_SRT_ingles.vtt'
                                ]}
                            />
                            <Special
                                customClasses={`absolute-content ${indicator===13 ? `content-active` : `content-disabled`}`}
                                background="/images/CAP_03/C3_12.jpg"
                            >
                                <div>
                                    <div className={styles.special_footer} style={{marginTop: 50}}>
                                        <Button onClick={handleAnnexed} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'chapter.3.C3_12.button.1' }) }} />
                                        <Button onClick={changeWithClick}>{ intl.formatMessage({ id: 'chapter.3.C3_12.button.2' })}</Button>
                                    </div>
                                </div>
                            </Special>
                            <Image
                                callback={changeWithClick}
                                customClasses={`absolute-content ${indicator===14 || indicator === 15 ? ` content-active` : ` content-disabled`}`}
                                uri="/images/CAP_03/A03_01.jpg"
                            />
                            <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                                callback={changeWithClick}
                                showControls={true}
                                index={16}
                                customClasses={`absolute-content ${indicator===16 ? `content-active` : `content-disabled`}`}
                                uri={"/VIDEOS_C3/A03_02.mp4"}
                                subtitles={[
                                    '/VIDEOS_C3/A03_02_SRT_catalan.vtt',
                                    '/VIDEOS_C3/A03_02_SRT_espanol.vtt',
                                    '/VIDEOS_C3/A03_02_SRT_ingles.vtt'
                                ]}
                            />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={17}
                            customClasses={`absolute-content ${indicator===17 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/A03_03.mp4"}
                            subtitles={[
                                '/VIDEOS_C3/A3_03_SRT_catalan.vtt',
                                '/VIDEOS_C3/A3_03_SRT_espanol.vtt',
                                '/VIDEOS_C3/A3_03_SRT_ingles.vtt'
                            ]}
                        />
                        <Image
                            callback={changeWithClick}
                            customClasses={`absolute-content ${indicator===18 || indicator===19 ? ` content-active` : ` content-disabled`}`}
                            uri="/images/CAP_03/A03_04.jpg"
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={20}
                            customClasses={`absolute-content ${indicator===20 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/A03_05.mp4"}
                            subtitles={[
                                '/VIDEOS_C3/A03_05_SRT_catalan.vtt',
                                '/VIDEOS_C3/A03_05_SRT_espanol.vtt',
                                '/VIDEOS_C3/A03_05_SRT_ingles.vtt'
                            ]}
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={21}
                            text={MESSAGES_VIDEOS_ANNEXED[indicator]}
                            customClasses={`absolute-content ${indicator===21 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/A03_06.mp4"}
                            subtitles={[
                                '/VIDEOS_C3/A03_06_SRT_catalan.vtt',
                                '/VIDEOS_C3/A03_06_SRT_espanol.vtt',
                                '/VIDEOS_C3/A03_06_SRT_ingles.vtt'
                            ]}
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={22}
                            customClasses={`absolute-content ${indicator===22 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/A03_07.mp4"}
                            subtitles={[
                                '/VIDEOS_C3/A03_07_SRT_catalan.vtt',
                                '/VIDEOS_C3/A03_07_SRT_espanol.vtt',
                                '/VIDEOS_C3/A03_07_SRT_ingles.vtt'
                            ]}
                        />
                        <Image
                            callback={changeWithClick}
                            customClasses={`absolute-content ${indicator ===23 || indicator ===24 ? ` content-active` : ` content-disabled`}`}
                            uri="/images/CAP_03/A03_08.jpg"
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={25}
                            customClasses={`absolute-content ${indicator ===25 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/A03_09.mp4"}
                            subtitles={[
                                '/VIDEOS_C3/A03_09_SRT_catalan.vtt',
                                '/VIDEOS_C3/A03_09_SRT_espanol.vtt',
                                '/VIDEOS_C3/A03_09_SRT_ingles.vtt'
                            ]}
                        />
                        <Special
                            customClasses={`absolute-content ${indicator ===26 ? `content-active` : `content-disabled`}`}
                        >
                            <Image
                                callback={changeWithClick}
                                customClasses={`${`absolute-content content-active`}`}
                                uri="/images/CAP_03/A03_10.jpg"
                            />
                            <div style={{zIndex: 50}}>
                                <div className={styles.special_footer}>
                                    <Button onClick={changeWithClick}>{ intl.formatMessage({ id: 'animation6.button2' })}</Button>
                                </div>
                            </div>
                        </Special>
                        {/* Annexed end */}
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={27}
                            customClasses={`absolute-content ${indicator=== 27 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/C3_13.mp4"}
                            subtitles={[
                                '/VIDEOS_C3/C3_13_SRT_catalan.vtt',
                                '/VIDEOS_C3/C3_13_SRT_espanol.vtt',
                                '/VIDEOS_C3/C3_13_SRT_ingles.vtt'
                            ]}
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={28}
                            text={MESSAGES_VIDEOS[28]}
                            customClasses={`absolute-content ${indicator=== 28 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/C3_14.mp4"}
                            subtitles={[
                                '/VIDEOS_C3/C3_14_SRT_catalan.vtt',
                                '/VIDEOS_C3/C3_14_SRT_espanol.vtt',
                                '/VIDEOS_C3/C3_14_SRT_ingles.vtt'
                            ]}
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={29}
                            customClasses={`absolute-content ${indicator=== 29 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/C3_15.mp4"}
                            subtitles={[
                                '/VIDEOS_C3/C3_15_SRT_catalan.vtt',
                                '/VIDEOS_C3/C3_15_SRT_espanol.vtt',
                                '/VIDEOS_C3/C3_15_SRT_ingles.vtt'
                            ]}
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={30}
                            customClasses={`absolute-content ${indicator=== 30 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/C3_16.mp4"}
                            subtitles={[
                                '/VIDEOS_C3/C3_16_SRT_catalan.vtt',
                                '/VIDEOS_C3/C3_16_SRT_espanol.vtt',
                                '/VIDEOS_C3/C3_16_SRT_ingles.vtt'
                            ]}
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={31}
                            customClasses={`absolute-content ${indicator=== 31 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/C3_17.mp4"}
                            subtitles={[
                                '/VIDEOS_C3/C3_17_SRT_catalan.vtt',
                                '/VIDEOS_C3/C3_17_SRT_espanol.vtt',
                                '/VIDEOS_C3/C3_17_SRT_ingles.vtt'
                            ]}
                        />
                        <Image
                            callback={changeWithClick}
                            customClasses={`absolute-content ${indicator === 32 ? ` content-active` : ` content-disabled`}`}
                            uri="/images/CAP_03/c3_18.jpg"
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={33}
                            customClasses={`absolute-content ${indicator=== 33 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/C3_18B.mp4"}
                            subtitles={['','','']}
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={34}
                            text={MESSAGES_VIDEOS[34]}
                            text1={MESSAGES_VIDEOS[34_1]}
                            customClasses={`absolute-content ${indicator=== 34 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/C3_19.mp4"}
                            subtitles={[
                                '/VIDEOS_C3/C3_19_SRT_catalan.vtt',
                                '/VIDEOS_C3/C3_19_SRT_espanol.vtt',
                                '/VIDEOS_C3/C3_19_SRT_ingles.vtt'
                            ]}
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={35}
                            customClasses={`absolute-content ${indicator=== 35 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/C3_20.mp4"}
                            subtitles={[
                                '/VIDEOS_C3/C3_20_SRT_catalan.vtt',
                                '/VIDEOS_C3/C3_20_SRT_espanol.vtt',
                                '/VIDEOS_C3/C3_20_SRT_ingles.vtt'
                            ]}
                        />
                        <Image
                            callback={changeWithClick}
                            customClasses={`absolute-content ${indicator === 36 || indicator === 37 ? ` content-active` : ` content-disabled`}`}
                            uri="/images/CAP_03/C3_21.jpg"
                        />
                        <Image
                            callback={changeWithClick}
                            customClasses={`absolute-content ${indicator === 38 ? ` content-active` : ` content-disabled`}`}
                            uri="/images/CAP_03/c3_22.jpg"
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={39}
                            text={MESSAGES_VIDEOS[39]}
                            customClasses={`absolute-content ${indicator=== 39 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/C3_23.mp4"}
                            subtitles={[
                                '/VIDEOS_C3/C3_23_SRT_catalan.vtt',
                                '/VIDEOS_C3/C3_23_SRT_espanol.vtt',
                                '/VIDEOS_C3/C3_23_SRT_ingles.vtt'
                            ]}
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={false}
                            index={40}
                            customClasses={`absolute-content ${indicator=== 40 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/C3_24.mp4"}
                            subtitles={['','','']}
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={41}
                            customClasses={`absolute-content ${indicator=== 41 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/C3_25.mp4"}
                            subtitles={['','','']}
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={() => {
                                setShowMessage(true)
                                changeWithClick()
                            }}
                            showControls={true}
                            index={42}
                            customClasses={`absolute-content ${indicator=== 42 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/C3_26.mp4"}
                            subtitles={[
                                '/VIDEOS_C3/C3_26_SRT_catalan.vtt',
                                '/VIDEOS_C3/C3_26_SRT_espanol.vtt',
                                '/VIDEOS_C3/C3_26_SRT_ingles.vtt'
                            ]}
                        />
                        <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                            callback={changeWithClick}
                            showControls={true}
                            index={43}
                            text={MESSAGES_VIDEOS[43]}
                            customClasses={`absolute-content ${indicator=== 43 ? `content-active` : `content-disabled`}`}
                            uri={"/VIDEOS_C3/C3_27.mp4"}
                            subtitles={[
                                '/VIDEOS_C3/C3_27_SRT_catalan.vtt',
                                '/VIDEOS_C3/C3_27_SRT_espanol.vtt',
                                '/VIDEOS_C3/C3_27_SRT_ingles.vtt'
                            ]}
                        />
                            <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                                callback={changeWithClick}
                                showControls={true}
                                index={44}
                                text={MESSAGES_VIDEOS[44]}
                                customClasses={`absolute-content ${indicator=== 44 ? `content-active` : `content-disabled`}`}
                                uri={"/VIDEOS_C3/C3_28.mp4"}
                                subtitles={[
                                    '/VIDEOS_C3/C3_28_SRT_catalan.vtt',
                                    '/VIDEOS_C3/C3_28_SRT_espanol.vtt',
                                    '/VIDEOS_C3/C3_28_SRT_ingles.vtt'
                                ]}
                            />
                            <Special customClasses={`absolute-content ${indicator=== 45 ? `content-active` : `content-disabled`}`} background="/images/CAP_03/C3_29.jpg">
                                <div>
                                    <div className={styles.special_footer}>
                                        <Button onClick={()=>handleFinal("/chapters?show=true&chapter=4")}>{ intl.formatMessage({ id: 'button.2.final.chapter.1' })}</Button>
                                    </div>
                                </div>
                            </Special>
                            {/* End Content */}
                        {
                            indicator === 3 &&
                            <SpecialText
                                title={intl.formatMessage({ id: 'chapter.3.C3_02.title' })}
                                text={intl.formatMessage({ id: 'chapter.3.C3_02.text' })}
                            />
                        }{
                            MESSAGES_COUNTER[indicator] && MESSAGES_COUNTER[indicator].map((text,i) =>(
                                <DynamicText
                                    key={text}
                                    customClasses={`
                                        ${styles.dynamic_text}
                                        ${ indicator === 15 || indicator === 19 ? `${styles.special_dynamic_text_1}` : ''}
                                    `}
                                    style={{
                                        background: '#d3ca29',
                                        marginTop: `${i * 50}px`,
                                        top: indicator === 19 || indicator === 24 ? '15%' : '',
                                        left: indicator === 19 || indicator === 24 ? '50%' : '',
                                        transform: indicator === 24 ? 'translateX(-50%)' : ''
                                    }}
                                >
                                    { intl.formatMessage({ id: text}) }
                                </DynamicText>
                            ))
                        }{
                            MESSAGES[indicator] && MESSAGES[indicator].map((text,i) =>(
                                <DynamicText
                                    key={text}
                                    customClasses={
                                        `${styles.dynamic_text}
                                        ${ indicator === 32 ? `${styles.special_dynamic_text_1} ${styles.new_animation}` : ''}
                                        ${i === 1 ? styles.special_dynamic_text_2 : ''}
                                        `
                                    }
                                    style={{
                                        top: indicator === 37 ? '40%' : '',
                                        left: indicator === 37 ? '15%' : '',
                                        backgroundColor: indicator === 32 ? 'rgb(202,193,32)' : indicator === 37 ? 'rgb(139,128,228)' : '',
                                        marginTop: `${i * 42}px`,
                                        transform: indicator === 37 ? 'none' : '',
                                        textTransform: i === 4 && 'none',
                                        fontSize: i === 4 && '.8rem'
                                    }}
                                >
                                    { intl.formatMessage({ id: text}) }
                                </DynamicText>
                            ))
                        }{
                            SENTENCE[indicator] &&
                            <Sentence
                                text={intl.formatMessage({ id: SENTENCE[indicator].text })}
                                signature={SENTENCE[indicator].signature ? intl.formatMessage({ id: SENTENCE[indicator].signature })  : ''}
                            />
                        }
                        {
                            indicator > 13 && indicator < 27 ?
                            <Indicator add={() => toggleIndicator("add")}
                            remove={() => toggleIndicator("remove")} indicatorsArray={[14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]} indicator={indicator} /> :
                            <Indicator add={() => toggleIndicator("add")}
                            remove={() => toggleIndicator("remove")} indicatorsArray={[
                                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
                                27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
                                39, 40 ,41, 42, 43, 44, 45
                            ]} indicator={indicator} />
                        }
                        <Audio
                            uri={
                                indicator < 7 ? "/audios/CAP_03/MUS_01_CLARA.wav" :
                                (indicator >= 7 && indicator < 9) ? "" :
                                (indicator >= 9 && indicator < 14) ? "/audios/CAP_03/MUS_02.mp3" :
                                (indicator >= 14 && indicator < 17) ? "/audios/CAP_03/AMB_BALL.mp3" :
                                indicator === 17 ? "" :
                                (indicator >= 18 && indicator < 20) ? "/audios/CAP_03/mus_00_aurora.mp3" :
                                (indicator >= 20 && indicator < 23) ? "" :
                                (indicator >= 23 && indicator < 25) ? "/audios/CAP_03/mus_01_AURORA_DEF.mp3" :
                                indicator === 25 ? "/audios/CAP_03/AMB_05_DONES.mp3" :
                                indicator === 26 ? "/audios/CAP_03/AMB_BALL.mp3" :
                                (indicator >= 27 && indicator < 32) ? "/audios/CAP_03/AMB_03_CAMP.mp3" :
                                indicator === 32 ? "/audios/CAP_03/AMB_04_FABRICA.mp3" :
                                (indicator >= 33 && indicator < 35) ? "/audios/CAP_03/AMB_05_TEXTIL.mp3" :
                                indicator === 35 ? "" :
                                (indicator >= 36 && indicator < 38) ? "/audios/CAP_03/MUS_01_CLARA.wav" :
                                (indicator >= 38 && indicator < 40) ? "/audios/CAP_03/AMB_07_ERA.mp3" :
                                (indicator >=40 && indicator < 43) ? "/audios/CAP_03/AMB_08_RIU.mp3" :
                                indicator >=43 && "/audios/CAP_03/MUS_04.mp3"
                            }
                            indicator={indicator}
                            elements={[1, 6, 9, 13, 14, 15, 18, 19, 23, 24, 26, 32, 33, 36, 37, 38, 40, 41, 45]}
                        />
                        {/* Show Navigator */}
                        {
                            indicator > 1 ?
                            <>{
                                showMessage &&
                                <Navigate
                                    indicator={indicator}
                                    handleClick={changeWithClick}
                                />
                            }</>
                            :   hiddePercentLoad &&
                                <InitialNavigation
                                    showArrow={showMessage}
                                    handleClick={changeWithClick}
                                />
                        }
                        {/* Logo Fadrines */}
                        <a className={styles.logo} href="/">
                            <img src="/images/logo.svg" alt="Logo" />
                        </a>
                </div>
            </div>
        </>
    )
}

export default Animation3
import { useEffect, createRef } from "react";
import _ from "lodash";

import styles from "./Video.module.css";
import stylesModal from "../modal/Modal.module.css";
import { Play, Pause, Switch, TextMarks, Text } from "..";
import "../../styles/animation.css";
import Loader from "react-js-loader";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

import { phrasesByChapter } from "../../utils/handlePhrases";
import useVideo from "../../hooks/useVideo";
import { Progress } from "./Progress";

export const Video = ({
  uri,
  subtitles,
  customClasses,
  index,
  indicator,
  text,
  text1,
  showControls,
  callback,
  credits = false,
  intro = false
}) => {
  const { pathname } = useLocation();
  const chapter = pathname.split("/")[2];
    /**
   * Create ref for video
   */
  const videoElement = createRef(null);

  const {
    progress,
    pause,
    loaded,
    stylesInline,
    creditText,
    showSubtitles,
    language,
    toggleControls,
    handleChange,
    handleClickVideo,
  } = useVideo(videoElement, showControls, index, indicator, intro, credits, callback, uri, subtitles);

  const intl = useIntl();


  return (
    <div
      className={`grid relative ${styles.video_container} ${customClasses}`}
      tabIndex={-1}
    >
      {loaded && (
        <>
          <div className={`absolute ${stylesModal.modal}`}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <Loader
                type="spinner-default"
                bgColor={"#FFFFFF"}
                title={""}
                color={"#FFFFFF"}
                size={100}
              />
            </div>
          </div>
        </>
      )}
      <div
        className="absolute-content"
        ref={videoElement}
        onClick={_.debounce(handleClickVideo, 500)}
        dangerouslySetInnerHTML={{
          __html: `
          <video
            autoplay
            playsinline
            width="100%"
            height="100%"
            preload="metadata"
            style="object-fit: fill;"
            type="video/mp4"
          ></video>
          `
        }}
      />
      {showControls && (
        <div
          className={`absolute cursor-pointer ${styles.controls}`}
          onClick={() => toggleControls(videoElement.current?.children[0])}
        >
          <div className={`grid full-w full-h ${styles.actions}`}>
            <button id="click-play-video">
              {pause ? <Play /> : <Pause />}
            </button>
            <div className={`grid absolute ${styles.progress}`}>
              <Progress strokeWidth={3} percentage={progress} />
            </div>
          </div>
        </div>
      )}
      {!credits && language === "vl" && showControls && (
        <Switch isOn={showSubtitles} handleToggle={handleChange} />
      )}
      {text && (
        <TextMarks
          text={text}
          style={phrasesByChapter(chapter, index, progress, 0)}
          chapter={chapter}
        />
      )}
      {text1 && (
        <TextMarks
          text={text1}
          style={phrasesByChapter(chapter, index, progress, 1)}
          chapter={chapter}
        />
      )}
      {credits && (
        <div className={`absolute-content flex-column content-center`}>
          {creditText.map((credit) => (
            <Text
              className={`${styles.credit_text}`}
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: credit }),
              }}
              style={stylesInline}
            />
          ))}
        </div>
      )}
    </div>
  );
};

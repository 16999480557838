const chapters = {
    ACCORDION: [
        {
            number: 0,
            text: 'accordion.item0',
            image: '/portadas/00_INTRO.jpg'
        },
        {
            number: 1,
            text: 'accordion.item1',
            image: '/images/bg-07.jpg'
        },
        {
            number: 2,
            text: 'accordion.item2',
            image: '/portadas/02_BEN MENJADA_BEN GUARDADA.jpg'
        },
        {
            number: 3,
            text: 'accordion.item3',
            image: '/portadas/03_BÈSTIA_SOLTA_02.jpeg'
        },
        {
            number: 4,
            text: 'accordion.item4',
            image: '/portadas/04_NO ME VENIA MAI B�.jpg'
        },
        {
            number: 5,
            text: 'accordion.item5',
            image: '/portadas/05_JA NO VAIG SER MAI DONA.jpg'
        },
    ]
}

module.exports = chapters;
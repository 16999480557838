import React from 'react';
import styles from './Menu.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { Raya, Facebook, Instagram } from '..';
import { useIntl } from 'react-intl';
import useAppContext from '../../context/Context';

import useWindowSize from '../../hooks/useWindowSize';

const CHAPTERS_TITLE = {
  1: 'accordion.item1',
  2: 'accordion.item2',
  3: 'accordion.item3',
  4: 'accordion.item4',
  5: 'accordion.item5',
};

export const Menu = ({ showSocial, customClasses }) => {
  const [width, height] = useWindowSize();

  const intl = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setIndicator } = useAppContext();
  let chapter = pathname.split('/')[2];
  const [isHover, setIsHover] = React.useState(false);

  const handleNavigate = (link, blank = false) => {
    //close or open menu conditions
    if (width <= 1200) {
      if (!isHover) {
        setIsHover(true)
        return
      }
    }

    // redirection conditions
    if (blank) {
      window.open(link, '_blank');
    } else {
      navigate(link);
      setIndicator(1);
    }

    setIsHover(false)
  };

  return (
    <div
      role='menu'
      className={`${styles.menu} ${
        isHover ? styles.menu_hover : ''
      } ${customClasses}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div
        role='menuitem'
        className={`cursor-pointer  ${styles.menu_item}`}
        onClick={() => handleNavigate('/')}
      >
        <Item changeColor={showSocial} />
        <MenuItemContent
          changeColor={showSocial}
          name={intl.formatMessage({ id: 'menu.start' })}
        />
      </div>
      <div
        role='menuitem'
        className={`cursor-pointer flex-column align-center content-center ${styles.menu_item}`}
        onClick={() => handleNavigate('/chapters?show=true')}
      >
        <Item changeColor={showSocial} />
        <MenuItemContent
          changeColor={showSocial}
          name={intl.formatMessage({ id: 'menu.chapters' })}
        />
      </div>
      <div
        role='menuitem'
        className={`cursor-pointer flex-column align-center content-center ${styles.menu_item}`}
        onClick={() => handleNavigate(process.env.REACT_APP_BLOG_URL, true)}
      >
        <Item changeColor={showSocial} />
        <MenuItemContent
          changeColor={showSocial}
          name={intl.formatMessage({ id: 'menu.blog' })}
        />
      </div>
      {showSocial && (
        <div
          role='menuitem'
          className={`cursor-pointer flex-column align-center content-center relative ${styles.menu_item}`}
        >
          <Item changeColor={showSocial} />
          <div className={`${styles.social}`}>
            <div
              onClick={() => handleNavigate(process.env.REACT_APP_INSTAGRAM_URL, true)}
            >
              <Instagram type={showSocial ? 'white' : 'blue'} />
            </div>
            <div
              onClick={() => handleNavigate(process.env.REACT_APP_FACEBOOK_URL, true)}
            >
              <Facebook type={showSocial ? 'white' : 'blue'} />
            </div>
          </div>
        </div>
      )}
      {showSocial && chapter && (
        <div className={`flex-row align-center ${styles.detail}`}>
          <span>{intl.formatMessage({ id: CHAPTERS_TITLE[chapter] })}</span>
          <div className={styles.detail_raya}></div>
        </div>
      )}
    </div>
  );
};

const Item = ({ changeColor }) => (
  <div
    className={styles.item}
    style={{
      backgroundColor: changeColor ? '#fff' : '',
    }}
  />
);

const MenuItemContent = ({ name, changeColor }) => (
  <div
    className={styles.menu_item_content}
    style={{
      color: changeColor ? '#fff' : '',
    }}
    role='menu-content'
  >
    {name}
  </div>
);

import React from 'react';
import ReactDOM from 'react-dom/client';
import { AppContextProvider } from './context/Context'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import './styles/index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
      <AppContextProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
      </AppContextProvider>
  </HelmetProvider>
);

import { useState, useEffect } from "react";
import { useIntl } from "react-intl";

import { Button } from "../button/Button";
import { Modal } from "../modal/Modal";
import cookies from "../../utils/cookies";

import styles from "./cookies.module.css"

export const Cookies = ({setShowCookies}) => {
    const intl = useIntl();

    const [activeButton, setActiveButton] = useState(true);
    const [showModal, setShowModal] = useState(false)

    const activePopup = () => {
        setShowModal(true)
    }

    const handleClick = (e, value) => {
        e.stopPropagation();
        
        cookies.set("__FADRINES_COOKIES__", value)
        setActiveButton(!activeButton);
        setShowCookies(false);
    }

    useEffect(() => {
        document.getElementById("cookie-policy").addEventListener("click", activePopup)
    })

    return(
        <>
            <div className={`${styles.cookies_container}`}>
                <div className="flex-row align-center">
                    <span dangerouslySetInnerHTML={{ __html: intl.formatMessage({id: "cookie-text"})}} />
                    <Button onClick={e => handleClick(e, true)} customClasses={activeButton ? styles.button_selected : ""}>{intl.formatMessage({id: 'cookie-button-accept'})}</Button>
                    <Button onClick={e => handleClick(e, false)} customClasses={!activeButton ? styles.button_selected : ""}>{intl.formatMessage({id: 'cookie-button-reject'})}</Button>
                </div>
            </div>
            {
                showModal &&
                <Modal 
                    callback={() => setShowModal(false)}
                    title={intl.formatMessage({ id: 'legal.policy'})}
                    text={intl.formatMessage({ id: 'legal.policy.text' })}
                />
            }
        </>
    )
}
import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { Video, Button, Audio } from "../../components";
import styles from "./home.module.css"

const VideoIntro = () => {
    const navigate = useNavigate();
    const [hiddePercentLoad, setHiddePercentLoad] = React.useState(true)

    const intl = useIntl()

    const changeWithClick = () => {
        navigate("/chapters?show=true&chapter=1");
    }

    const handleLoaded = () => {
        setHiddePercentLoad(true)
        console.log("Loaded!!!");
    }

    return(
        <div className="full-window-w full-window-h relative">
            <div
                className={`absolute full-window-w full-window-h`}
                style={{
                    backgroundColor: !hiddePercentLoad ? "white" : "",
                    zIndex: !hiddePercentLoad ? "40" : ""
                }}
            />
            <Video
                indicator={1}
                setVideoLoaded={handleLoaded}
                intro={true}
                callback={changeWithClick}
                showControls={true}
                index={1}
                customClasses={`absolute-content content-active`}
                uri={"/INTRO/INTRO.mp4"}
                subtitles={[
                    '/INTRO/INTRO_SRT_catalan.vtt',
                    '/INTRO/INTRO_SRT_espanol.vtt',
                    '/INTRO/INTRO_SRT_ingles.vtt'
                ]}
            />
            <div className={styles.special_footer}>
                <Button onClick={changeWithClick}>{ intl.formatMessage({ id: 'introduction' })}</Button>
            </div>
            <Audio
                uri=""
                indicator={1}
                elements={[1]}
            />
        </div>
    )
}

export default VideoIntro;
import React, { useEffect, useState } from 'react';

import { Head, Expand, Menu } from '..';
import styles from './Layout.module.css';
import { IntlProvider } from 'react-intl';
import en from '../../lang/en.json';
import cs from '../../lang/es.json';
import vl from '../../lang/vl.json';
import useAppContext from '../../context/Context';
import { useLocation } from 'react-router-dom';
import Static from '../../modules/static/static';
import { Cookies } from '../cookies';
import '../../styles/global.css';
import stylesModal from "../modal/Modal.module.css"
import { Button } from '..';

import cookies from '../../utils/cookies';

export const Layout = ({ children }) => {
  const { language, handle } = useAppContext();
  const [showCookies, setShowCookies] = React.useState(true);
  const location = useLocation();
  const [acceptFullScreen, setAcceptFullScreen] = useState(true);

  const messages = {
    en: en,
    cs: cs,
    vl: vl,
  };

  const getLanguage = (locale) => {
    const dash_index = locale.indexOf('-');
    if (dash_index >= 0) {
      return locale.substring(0, dash_index);
    }
    return locale;
  };

  useEffect(() => {
    const localCookies = cookies.get("__FADRINES_COOKIES__");
    if (localCookies) setShowCookies(false);
  },[])

  const acceptCookies = () => {
    const localCookies = cookies.get("__FADRINES_COOKIES__");
    if (!localCookies) {
      cookies.set("__FADRINES_COOKIES__", true);
      setShowCookies(false);
    }
  }

  function openFullscreen() {
    const elem = document.querySelector('#fadrines-layout')

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    }

    setAcceptFullScreen(false)
  }

  useEffect(() => {
    function onFullscreenChange() {
      if (!document.fullscreenElement) {
        setAcceptFullScreen(true)
      }
    }
    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () => document.removeEventListener('fullscreenchange', onFullscreenChange)
  })

  document.addEventListener("visibilitychange", function() {
      if (document.hidden){
          // focus is in other tab browser
      } else {
          window.focus()
      }
  });

  return (
    <IntlProvider
      locale={getLanguage(language)}
      defaultLocale="es"
      messages={messages[getLanguage(language)]}
    >
      <Head content="Fadrines" />
      <div id='fadrines-layout' onClick={acceptCookies}>
        {showCookies && <Cookies setShowCookies={setShowCookies} />}
        {
          <div
            className={`absolute cursor-pointer z-index-50 ${styles.expand}`}
            onClick={handle.enter}
            style={{
              top:
                location.pathname !== '/' && location.pathname !== '/chapters'
                  ? 40
                  : '',
              right:
                location.pathname !== '/' && location.pathname !== '/chapters'
                  ? 15
                  : '',
            }}
          >
            <Expand
              type={
                location.pathname === '/chapters' || location.pathname === '/'
                  ? 'blue'
                  : 'white'
              }
              customText={
                location.pathname !== '/' && location.pathname !== '/chapters'
                  ? styles.custom_text
                  : ''
              }
              style={{
                flexDirection:
                  location.pathname !== '/' && location.pathname !== '/chapters'
                    ? 'row-reverse'
                    : '',
              }}
            />
          </div>
        }
        <Menu
          showSocial={
            location.pathname !== '/' && location.pathname !== '/chapters'
          }
          customClasses={`absolute z-index-50 ${styles.menu}`}
        />
        {<Static />}
        {children}
      </div>

      {
        acceptFullScreen &&
        <div className={`absolute ${stylesModal.modal} ${styles.full_screen_mobile}`}>
          <div>
            <h1>{ messages[getLanguage(language)]['mobile-full-screen-experience'] }</h1>
            <Button onClick={openFullscreen}>{ messages[getLanguage(language)]['cookie-button-accept'] }</Button>
          </div>
        </div>
      }
    </IntlProvider>
  );
};

import React from "react";
import { Routes, Route } from 'react-router-dom'

import { Layout } from './components'
import Start from './modules/start/start';
import VideoIntro from './modules/videoIntro/VideoIntro';
import Animation from './modules/animation/animation';
import Animation2 from './modules/animation2/animation2';
import Animation3 from './modules/animation3/animation3';
import Animation4 from './modules/animation4/animation4';
import Animation5 from './modules/animation5/animation5';
import Chapters from './modules/chapters/chapters';
import useAppContext from "./context/Context";

const App = () => {
    const {handle, FullScreen} = useAppContext();

    return(
        <FullScreen className={`relative full-w full-h`} handle={handle}>
            <Routes>        
                {/* <Route path="*" element={<Navigate to="/" replace /> }/> */}
                <Route path="/" element={<Layout><Start /></Layout>}/>
                <Route path='/chapters' element={<Layout><Chapters /></Layout>} />                
                <Route exact path="/chapters/1" element={<Layout><Animation /></Layout>}/>
                <Route exact path="/chapters/2" element={<Layout><Animation2 /></Layout>}/>
                <Route exact path="/chapters/3" element={<Layout><Animation3 /></Layout>}/>
                <Route exact path="/chapters/4" element={<Layout><Animation4 /></Layout>}/>
                <Route exact path="/chapters/5" element={<Layout><Animation5 /></Layout>}/>
                <Route exact path="/video-intro" element={<Layout><VideoIntro /></Layout>}/>
            </Routes>
        </FullScreen>
    )
}

export default App;
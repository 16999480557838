const cookies = {
    get:(cName) =>{
        return localStorage.getItem(cName);
    },
    set:(cName, cValue, expDays) =>{
        const now = new Date()
        const item = {
            value: cValue,
            expiry: now.getTime() + expDays
        }
        localStorage.setItem(cName, JSON.stringify(item));
    }
}

module.exports = cookies;
import React from 'react'
import ReactAudioPlayer from 'react-audio-player'
import useAppContext from '../../context/Context'
import { Volume } from '..'
import styles from './Audio.module.css'

export const Audio = ({uri, indicator, elements }) =>{
    const { volumenAudio, setVolumenAudio, pauseAllMedia } = useAppContext()

    const defineVolume = () => {
        if (elements.find(element => element === indicator)) {
            setVolumenAudio(100)
        }else{
            setVolumenAudio(25)
        }
    }

    React.useEffect(() => {
        if(volumenAudio > 0) defineVolume();
    },[indicator])


    React.useEffect(() => {
        const audioTag = document.querySelector("#audio-id-component");
        if (pauseAllMedia) {
            audioTag.pause();
            setVolumenAudio(0);
        } else {
            audioTag.play();
            defineVolume();
        }
    },[pauseAllMedia])

    const handleVolumen = () => {
        if (volumenAudio > 0) setVolumenAudio(0);
        else defineVolume();
    }

    return(
        <>
            <ReactAudioPlayer
                id="audio-id-component"
                src={uri}
                autoPlay={true}
                controls
                volume={volumenAudio/100}
                style={{ display: 'none'}}
                loop={true}
            />
            <HandleVolume {...{volumenAudio, handleVolumen}} />
        </>
    )
}

const HandleVolume = ({volumenAudio, handleVolumen}) =>{
    return(
        <div className={`flex-column absolute ${styles.audio_controller}`}>
            <div onClick={handleVolumen} className={styles.indicator}>
                <Volume 
                    volume={volumenAudio > 0}
                />
            </div>
        </div>
    )
}
import styles from './Video.module.css'

export const Progress = ({ strokeWidth, percentage }) => {
	const radius = (30 - strokeWidth / 2);
    const pathDescription = `
      M 30,30 m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

    const diameter = Math.PI * 2 * radius;
    const progressStyle = {
			stroke:"#333",
  		strokeLinecap: 'round',
      strokeDasharray: `${diameter}px ${diameter}px`,
      strokeDashoffset: `${((100 - percentage) / 100 * diameter)}px`,
    };

    return (
      <svg
        className={`absolute ${styles.CircularProgressbar}`}
        viewBox="0 0 60 60"
				width={51}
				height={51}
      >
        <path
          className="CircularProgressbar-trail"
          d={pathDescription}
          strokeWidth={strokeWidth}
          fillOpacity={0}
					style={{
						stroke:"#fff"
					}}
        />
        <path
          className="CircularProgressbar-path"
          d={pathDescription}
          strokeWidth={strokeWidth}
          fillOpacity={0}
          style={progressStyle}
        />
      </svg>
    );
};
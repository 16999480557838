import React from 'react';

import arrow from '../../assets/arrow.svg';
import styles from './LoadChapter.module.css';

export const LoadChapter = ({
  percent,
  handleClick,
  hiddePercent,
  imageUrl,
}) => {
  return (
    <div
      className={`z-index-50 absolute full-window-w full-window-h ${
        styles.load_chapter_container
      } ${hiddePercent ? styles.hidden : ''}`}
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
    >
        <div
          className={`${styles.continue} cursor-pointer`}
          onClick={handleClick}
        >
          <div
            className={`z-index-50 ${styles.navigate}`}
            style={{
              bottom: 20,
            }}
          >
            <img
              className={styles.arrow}
              alt='open-chapter'
              src={arrow}
              style={{
                width: 30,
              }}
            />
          </div>
        </div>
    </div>
  );
};

import { useState, useEffect } from "react";
import _ from "lodash";

import useAppContext from "../context/Context";

const pathMedia = process.env.REACT_APP_MEDIA_SERVER;

const CREDIT_TEXTS = [
  ["credits.text.1", "credits.text.2", "credits.text.3", "credits.text.4"],
  ["credits.text.5", "credits.text.6", "credits.text.7"],
  ["credits.text.8", "credits.text.9"],
  ["credits.text.10", "credits.text.11"],
  ["credits.text.12", "credits.text.13"],
  ["credits.text.14", "credits.text.15", "credits.text.16"],
  ["credits.text.17"],
];

const useVideo = (
  videoElement,
  showControls,
  index,
  indicator,
  intro,
  credits,
  callback,
  uri,
  subtitles
) => {
  const [progress, setProgress] = useState(0);
  const [pause, setPause] = useState(false);
  const [creditText, setCreditText] = useState([]);
  const [muted, setMuted] = useState(false);
  /**
   * Handle video loaded
   */
  const [loaded, setLoaded] = useState(false);
  /**
   * Style for equals text
   */
  const [stylesInline, setStyleInline] = useState({
    opacity: 0,
    animation: "second 1s linear",
  });

  /**
   * Variables context provider
   */
  const {
    volumenAudio,
    showSubtitles,
    setShowSubtitles,
    language,
    pauseAllMedia,
    setPauseAllMedia,
  } = useAppContext();

  const handleProgress = (e, played) => {
    const { duration, currentTime, readyState } = e.target;
    let actualProgress = (currentTime * 100) / duration;

    if (readyState < 3 && actualProgress > 1) {
      setLoaded(true);
    } else {
      setLoaded(false);

      setProgress(actualProgress);

      if (showControls) {
        if (index === indicator) {
          if (intro || credits) {
            if (actualProgress > 99.89) {
              played.pause();
              callback(e);
            }
          } else {
            if (actualProgress > 99) {
              played.pause();
              if (indicator === 42) {
                setPause(true);
              } else {
                callback(e);
              }
            }
          }
        }
      }
      if (credits) {
        handleCredits(actualProgress);
      }
    }
  };

  /**
   * Handle credits
   */
  const handleCredits = actualProgress => {
    setStyleInline({
      animation: "second 1s linear",
      opacity: 0,
    });
    if (actualProgress > 3 && actualProgress <= 11) {
      setCreditText(CREDIT_TEXTS[0]);
      setStyleInline({
        animation: "first 1s linear",
        opacity: 1,
      });
    } else if (actualProgress > 12 && actualProgress <= 21) {
      setCreditText(CREDIT_TEXTS[1]);
      setStyleInline({
        animation: "first 1s linear",
        opacity: 1,
      });
    } else if (actualProgress > 22 && actualProgress <= 29) {
      setCreditText(CREDIT_TEXTS[2]);
      setStyleInline({
        animation: "first 1s linear",
        opacity: 1,
      });
    } else if (actualProgress > 30 && actualProgress <= 39) {
      setCreditText(CREDIT_TEXTS[3]);
      setStyleInline({
        animation: "first 1s linear",
        opacity: 1,
      });
    } else if (actualProgress > 40 && actualProgress <= 72) {
      setCreditText(CREDIT_TEXTS[4]);
      setStyleInline({
        animation: "first 1s linear",
        opacity: 1,
      });
    } else if (actualProgress > 73 && actualProgress <= 84) {
      setCreditText(CREDIT_TEXTS[5]);
      setStyleInline({
        animation: "first 1s linear",
        opacity: 1,
      });
    } else if (actualProgress > 87 && actualProgress <= 97) {
      setCreditText(CREDIT_TEXTS[6]);
      setStyleInline({
        animation: "first 1s linear",
        opacity: 1,
      });
    }
  };

  /**
   * Method for click event of video controls
   */
  const toggleControls = (played) => {
    if (pauseAllMedia) {
      played.pause();
      setPause(true);
    } else {
      played.play();
      setPause(false);
    }
    setPauseAllMedia(!pauseAllMedia);
  };

  /**
   * Hide or show subtitles
   */
  const handleChange = () => {
    setShowSubtitles(!showSubtitles);
  };

  const showHideSubtitle = () => {
    const playedCustom = videoElement.current.children[0]
    const trackElement = document.createElement('track');
    trackElement.label = 'Valenciano';
    trackElement.kind = 'captions';
    trackElement.srclang = 'vl';
    trackElement.src = `/videos${subtitles[0]}`;
    trackElement.default = true;

    if (playedCustom) {
      if (showSubtitles) {
        playedCustom.appendChild(trackElement);
      } else {
        const tracks = playedCustom.querySelectorAll('track')
        tracks.forEach((track) => {
          if (track.label === 'Valenciano') {
            playedCustom.removeChild(track);
          }
        });
      }
    }
  }

  useEffect(() => {
    showHideSubtitle()
  }, [showSubtitles]);

  const handleClickVideo = () => {
    if (!intro && !loaded) {
      callback();
    }
  };

  const handleSubtitles = (index, playedCustom) => {
    for (let i = 0; i < playedCustom.textTracks.length; i++) {
      if (
        playedCustom.textTracks[i].language === language &&
        index === indicator
      ) {
        playedCustom.textTracks[i].mode = "showing";
      } else playedCustom.textTracks[i].mode = "hidden";
    }
  };

  const makeAutoplay = (playedCustom) => {
    const promise = playedCustom.play();
    if (promise !== 'undefined') {
      promise
      .then(_ => {
        setPause(false);
        if (evaluateSubtitles()) handleSubtitles(index, playedCustom);
      })
      .catch(error => { /** handle error */ })
    }
  };

  const handleAutoplay = (playedCustom) => {
    setPauseAllMedia(false);
    if (index === indicator) {
      setProgress(0);
      playedCustom.src = `${pathMedia}/video/1920${uri}`;
      playedCustom?.load();
      credits || uri === "/VIDEOS_C5/C5_02.mp4"
        ? (playedCustom.currentTime = 2)
        : (playedCustom.currentTime = 0);
        makeAutoplay(playedCustom)
    } else {
      playedCustom?.pause();
      setTimeout(() => {
        if (playedCustom) {
          playedCustom.currentTime = 0;
          playedCustom.removeAttribute("src");
          playedCustom.load();
          if (evaluateSubtitles()) handleSubtitles(index, playedCustom);
        }
      }, 1000);
    }
  };

  const updateStatusVideo = (playedCustom) => {
    if (index === indicator) {
      if (pauseAllMedia) {
        playedCustom.pause();
        setPause(true);
      } else {
        playedCustom.play();
        setPause(false);
      }
    }
  };

  const evaluateSubtitles = () => subtitles.every(s => s !== '')

  const detectPosterForVideo = () => {
    const uriSplit = uri.split('/')
    const folderName = uriSplit[1]
    const videoName = uriSplit[2].split('.')[0]

    return `/poster/${folderName}/${videoName}.png`
  }

  useEffect(() => {
    const playedCustom = videoElement.current?.children[0]
    playedCustom.loop = !showControls
    playedCustom.muted = muted
    playedCustom.poster = detectPosterForVideo()
    playedCustom.ontimeupdate = (e) => handleProgress(e, playedCustom)
    if (showControls && evaluateSubtitles() && (index === indicator)) {
      playedCustom.innerHTML = `
      ${
        showSubtitles && `<track
          label="Valenciano"
          kind="captions"
          srcLang="vl"
          src="/videos${subtitles[0]}"
          default
        />`
      }
        <track
          label="Castellano"
          kind="captions"
          srcLang="cs"
          src="/videos${subtitles[1]}"
        />
        <track
          label="English"
          kind="captions"
          srcLang="en"
          src="/videos${subtitles[2]}"
        />
      `
    }
    handleAutoplay(playedCustom)
  }, [indicator]);

  useEffect(() => updateStatusVideo(videoElement.current?.children[0]), [pauseAllMedia]);


  useEffect(() => {
    const playedCustom = videoElement.current?.children[0]
    subtitles && handleSubtitles(index, playedCustom);
  }, [language, indicator]);

  /**
   * Handle Volumen Changes
   *
   */
  useEffect(() => {
    const playedCustom = videoElement.current?.children[0]
    if (playedCustom) {
      if (volumenAudio > 0) {
        playedCustom.muted = false
      } else {
        playedCustom.muted = true
      }
    }
  }, [volumenAudio]);

  return {
    pathMedia,
    handleProgress,
    progress,
    setProgress,
    pause,
    setPause,
    loaded,
    stylesInline,
    creditText,
    showSubtitles,
    language,
    pauseAllMedia,
    setPauseAllMedia,
    toggleControls,
    handleChange,
    muted,
    handleClickVideo,
    handleSubtitles,
    handleAutoplay,
    updateStatusVideo,
    volumenAudio
  };
};

export default useVideo;

import React from 'react'
import { FullScreen, useFullScreenHandle } from "react-full-screen";

//Context
export const AppContext = React.createContext(null);

//Provider
export const AppContextProvider = ({ children }) => {
  const [volumenAudio, setVolumenAudio] = React.useState(100)
  const [indicator, setIndicator] = React.useState(1)
  const [language, setLanguage] = React.useState('vl')
  const [accordionActive, setAccordionActive] = React.useState(0)
  const [showSubtitles, setShowSubtitles] = React.useState(true)
  const [videoIntro, setVideoIntro] = React.useState(false)
  const handle = useFullScreenHandle();
  const [addClasses, setAddClasses] = React.useState(false)
  const [showMessage, setShowMessage] = React.useState(false);
  const [pauseAllMedia, setPauseAllMedia] = React.useState(false);
  const [hiddePercentLoad, setHiddePercentLoad] = React.useState(false)

  const [touchStartX, setTouchStartX] = React.useState(undefined);
	const [touchEnd, setTouchEnd] = React.useState(0);

    const resetInit = () => {
      setIndicator(1);
      setAddClasses(false);
      setShowMessage(false);
      setHiddePercentLoad(false)
    }

    const handleIndicator = {
      add: ({refer, matchesIndicatorsAdd}, total, sum = 1) => {
        toggleMatchIndicator(refer, matchesIndicatorsAdd);
        setTimeout(()=>{
          indicator < total && setIndicator && setIndicator(indicator + sum)
        },300)
      },
      remove: ({refer, matchesIndicatorsRemove}, total, res = 1) => {
        toggleMatchIndicator(refer, matchesIndicatorsRemove);
          setTimeout(()=>{
              indicator > 1 && (setIndicator && setIndicator(indicator - res))
          },300)
      },
    }

    const toggleMatchIndicator = (refer, matchesIndicators) => {
      if (matchesIndicators.find(match => match === indicator)) {
          if (refer.current) refer.current.style.backgroundColor = 'white'
          setAddClasses(true)
      }
    }

    // show/hide next diapo indicator
    const handleNavigate = (matchesIndicators, chapter) => {
      setShowMessage(false);
      if (chapter === 4 && (indicator >= 21 && indicator <= 25)) {
        setShowMessage(true);
        return
      }
      if (hiddePercentLoad) {
        let timer = setTimeout(() =>{
          if (matchesIndicators.find(match => match === indicator)) {
                setShowMessage(true)
                return
            }else{
                setShowMessage(false)
                clearTimeout(timer)
                return
            }
        },10000)
        return () => {
            clearInterval(timer);
        };
      }
    }

    React.useEffect(()=>{
      setTimeout(()=>{
          setAddClasses(false)
      },1600)
    },[addClasses])

    const handleTouchStart = e => setTouchStartX(e.targetTouches[0].clientX);

    const handleTouchMove = e => setTouchEnd(e.targetTouches[0].clientX);

    const handleTouchEnd = callback => {
      callback(touchStartX, touchEnd);
    };

    const values = {
      volumenAudio,
      setVolumenAudio,
      indicator,
      setIndicator,
      language,
      setLanguage,
      accordionActive,
      setAccordionActive,
      showSubtitles,
      setShowSubtitles,
      videoIntro,
      setVideoIntro,
      handle,
      FullScreen,
      handleIndicator,
      addClasses,
      showMessage,
      setShowMessage,
      handleNavigate,
      resetInit,
      pauseAllMedia,
      setPauseAllMedia,
      hiddePercentLoad,
      setHiddePercentLoad,
      handleTouchStart,
      handleTouchEnd,
      handleTouchMove
      } // States que serán visibles en el contexto.

    return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
}

//
export function useAppContext() {
  const context = React.useContext(AppContext);

  if(!context){
    console.error('Error deploying App Context!!!');
  }

  return context;
}

export default useAppContext;
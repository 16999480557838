import React from "react";
import { useNavigate } from "react-router-dom";
import {
    Image,
    Indicator,
    Video,
    Button,
    Special,
    Plus,
    Audio,
    Navigate,
    Modal,
    InitialNavigation,
    LoadChapter
} from '../../components'
import {useIntl} from 'react-intl'
import useAppContext from '../../context/Context'
import styles from '../animation2/animation2.module.css'

const Animation5 = () =>{
    const [percentLoad, setPercentLoad] = React.useState(100);
    const [hiddePercentLoad, setHiddePercentLoad] = React.useState(false)
    const { indicator, setIndicator, pauseAllMedia, setPauseAllMedia,
        handleTouchStart,
        handleTouchMove,
        handleTouchEnd, } = useAppContext()
    const intl = useIntl()
    const navigate = useNavigate()
    const [showCredits, setShowCredits] = React.useState(0);
    const [showMessage, setShowMessage] = React.useState(false)
    const [showModal, setShowModal] = React.useState(false)

    /**
     * Restart indicator
     */
     React.useState(() => {
        setIndicator && setIndicator(1)
    },[])

    const handleIndicator = {
        add: () => {
            setTimeout(()=>{
                indicator < 3 && setIndicator && setIndicator(indicator + 1)
            },300)
        },
        remove: () => {
            setTimeout(()=>{
                indicator > 1 && (setIndicator && setIndicator(indicator - 1))
            },300)
        },
    }

    const handleScroll = (e) => {
        if (showCredits === 0) {
            if( !showModal ){
                e.deltaY > 20 && handleIndicator.add()
                e.deltaY < -20 && handleIndicator.remove()
            }
        }
    }

    const pressFunction = (e) => {
        if (showCredits === 0) {
            if( !showModal ){
                switch (e.keyCode) {
                    case 32: setPauseAllMedia(!pauseAllMedia);
                    break;
                    case 40: handleIndicator.add()
                    break;
                    case 38: handleIndicator.remove()
                    break
                }
            }
        }
    }

    const changeWithClick = () => {
        handleIndicator.add()
    }

    const handleFinal = (redirect) => {
        navigate(redirect)
    }

    const handleShowCredits = () => {
        if( showCredits === 1) handleStart()
        else setShowCredits( showCredits + 1)
    }

    const handleNavigate = () => {
        setShowMessage(false)
        if (hiddePercentLoad){
            let timer = setTimeout(() =>{
                if(
                    (indicator === 1)
                ){
                    setShowMessage(true)
                    return
                }else{
                    setShowMessage(false)
                    clearTimeout(timer)
                    return
                }
            },10000)
            return () => {
                clearInterval(timer);
            };
        }
    }
    React.useEffect(handleNavigate,[indicator, hiddePercentLoad])

    const handleStart = () => {
       setTimeout(()=>{
            navigate("/");
       },1000)
    }

    const handleClickToStart = () => {
        navigate("/");
    }

    const loadCount = React.useRef(0);

    const setVideoLoaded = async() => {
        if (loadCount.current < 2) {
            loadCount.current = loadCount.current + 1;
            setPercentLoad(
                Math.round((loadCount.current * 100) / 2)
            )
        } else {
            console.log("All videos loaded!");
        }
    };

    const handleLoadResources = () => {
        setHiddePercentLoad(true)
        const box = document.getElementById('fadrines-animation-5');
        box.tabIndex = '-1';
        box.focus();
    }

    return(
        <>
            <LoadChapter
                percent={percentLoad}
                handleClick={handleLoadResources}
                hiddePercent={hiddePercentLoad}
                imageUrl="/images/CAP_05/CAP_05.jpg"
            />
            <div id="fadrines-animation-5" className={'relative full-window-w full-window-h'} onWheel={handleScroll} onKeyDown={pressFunction} tabIndex={-1}>
                <div className={`relative full-window-w full-window-h grid ${styles.images}`}>
                    {/* Content Start */}
                    <Image
                        callback={changeWithClick}
                        customClasses={`absolute-content ${(indicator===1 && hiddePercentLoad) ? ` content-active` : ` content-disabled`}`}
                        uri="/images/CAP_05/C5_00.jpg"
                    />
                    <Video setVideoLoaded={setVideoLoaded}  indicator={indicator}
                        callback={changeWithClick}
                        showControls={true}
                        index={2}
                        customClasses={`absolute-content ${indicator=== 2 ? `content-active` : `content-disabled`}`}
                        uri={"/VIDEOS_C5/C5_02.mp4"}
                        subtitles={[
                            '/VIDEOS_C5/C5_02_SRT_catalan.vtt',
                            '/VIDEOS_C5/C5_02_SRT_espanol.vtt',
                            '/VIDEOS_C5/C5_02_SRT_ingles.vtt'
                        ]}
                    />
                    <Special customClasses={`absolute-content ${indicator===3 ? `content-active` : `content-disabled`}`} background="/images/CAP_05/CAP_05.jpg">
                            <div className={styles.special_footer}>
                                <Button onClick={()=>handleFinal("/chapters?show=true")}>{ intl.formatMessage({ id: 'button.1.final.chapter.1' })}</Button>
                                <Button customClasses={styles.button_modal} onClick={handleShowCredits}>
                                    <div><Plus /></div>
                                    {intl.formatMessage({ id: 'credits'})}
                                </Button>
                            </div>
                    </Special>
                    <Video indicator={showCredits}
                        setVideoLoaded={setVideoLoaded}
                        callback={handleShowCredits}
                        showControls={true}
                        index={1}
                        credits={true}
                        customClasses={`absolute-content ${ showCredits === 1 ? `content-active` : `content-disabled`}`}
                        uri={"/VIDEOS_C5/CREDITS.mp4"}
                        subtitles={['','','']}
                    />
                    {
                        showCredits > 0 &&
                        <div className={styles.skip_video}>
                            <Button onClick={handleClickToStart}>{ intl.formatMessage({ id: 'credits.skip' })}</Button>
                        </div>
                    }
                    <Audio
                        uri={
                            showCredits === 1 ? "" :
                            indicator === 1 ? "/audios/CAP_05/C5_MUS1.mp3" :
                            indicator === 2 ? "" :
                            indicator === 3 && "/audios/CAP_05/C5_MUS1.mp3"
                        }
                        indicator={indicator}
                        elements={[1, 3]}
                        credits={true}
                    />
                    {/* Content End */}
                    {
                        showCredits === 0 &&
                        <Indicator indicatorsArray={[1, 2, 3]} indicator={indicator} add={() => handleIndicator.add()}
                                remove={() => handleIndicator.remove()} />
                    }
                    {
                            indicator > 1 ?
                            <>{
                                showMessage &&
                                <Navigate
                                    indicator={indicator}
                                    handleClick={changeWithClick}
                                />
                            }</>
                            :   hiddePercentLoad &&
                                <InitialNavigation
                                    showArrow={showMessage}
                                    handleClick={changeWithClick}
                                />
                    }
                    {/* Logo Fadrines */}
                    <a className={styles.logo} href="/">
                        <img src="/images/logo.svg" alt="Logo" />
                    </a>
                </div>
            </div>
        </>
    )
}

export default Animation5
import React from 'react'
import { useIntl } from 'react-intl'
import styles from './Expand.module.css'

export const Expand = ({type = 'blue', customText, ...otherProps}) =>(
    <div className={`flex-row align-center ${styles.expand_option}`} {...otherProps}>
    {
        type === 'blue' &&
        <svg width="40px" height="40px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Artboard" transform="translate(-1458.000000, -380.000000)" fill="#332BFF" fillRule="nonzero">
                    <g id="Group-15" transform="translate(1458.000000, 380.000000)">
                        <path d="M25.6962,18 L25.6962,19.5 L20.5602,19.499 L24.8555301,23.7943699 L23.7948699,24.8550301 L19.4992,20.56 L19.5002,25.821 L18.0002,25.821 L18.0002,18 L25.6962,18 Z M42.2011,18.0622 L42.2011,25.7582 L40.7011,25.7582 L40.7001,20.6232 L36.4062776,24.9184825 L35.3455224,23.8579175 L39.6401,19.5612 L34.3801,19.5622 L34.3801,18.0622 L42.2011,18.0622 Z M42.2009,34.1184 L42.2009,41.9394 L34.5049,41.9394 L34.5049,40.4394 L39.6389,40.4384 L35.3455224,36.1439825 L36.4062776,35.0834175 L40.6999,39.3784 L40.7009,34.1184 L42.2009,34.1184 Z M19.5,34.1812 L19.5,39.3152 L23.7948224,35.0209175 L24.8555776,36.0814825 L20.561,40.3762 L25.821,40.3772 L25.821,41.8772 L18,41.8772 L18,34.1812 L19.5,34.1812 Z" id="icono-expandir-azul"></path>
                    </g>
                </g>
            </g>
        </svg>
    }{
        type === 'white' &&
        <svg width="40px" height="40px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Artboard" transform="translate(-290.000000, -169.000000)" fill="#FFFFFF" fillRule="nonzero">
                    <g id="Group-14" transform="translate(290.000000, 169.000000)">
                        <path d="M25.6962,18 L25.6962,19.5 L20.5602,19.499 L24.8555301,23.7943699 L23.7948699,24.8550301 L19.4992,20.56 L19.5002,25.821 L18.0002,25.821 L18.0002,18 L25.6962,18 Z M42.2011,18.0622 L42.2011,25.7582 L40.7011,25.7582 L40.7001,20.6232 L36.4062776,24.9184825 L35.3455224,23.8579175 L39.6401,19.5612 L34.3801,19.5622 L34.3801,18.0622 L42.2011,18.0622 Z M42.2009,34.1184 L42.2009,41.9394 L34.5049,41.9394 L34.5049,40.4394 L39.6389,40.4384 L35.3455224,36.1439825 L36.4062776,35.0834175 L40.6999,39.3784 L40.7009,34.1184 L42.2009,34.1184 Z M19.5,34.1812 L19.5,39.3152 L23.7948224,35.0209175 L24.8555776,36.0814825 L20.561,40.3762 L25.821,40.3772 L25.821,41.8772 L18,41.8772 L18,34.1812 L19.5,34.1812 Z" id="icono-expandir-blanco"></path>
                    </g>
                </g>
            </g>
        </svg>
    }{
        type === 'pink' &&
        <svg width="40px" height="40px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Artboard" transform="translate(-374.000000, -169.000000)" fill="#FF1672" fillRule="nonzero">
                    <g id="Group-15" transform="translate(374.000000, 169.000000)">
                        <path d="M25.6962,18 L25.6962,19.5 L20.5602,19.499 L24.8555301,23.7943699 L23.7948699,24.8550301 L19.4992,20.56 L19.5002,25.821 L18.0002,25.821 L18.0002,18 L25.6962,18 Z M42.2011,18.0622 L42.2011,25.7582 L40.7011,25.7582 L40.7001,20.6232 L36.4062776,24.9184825 L35.3455224,23.8579175 L39.6401,19.5612 L34.3801,19.5622 L34.3801,18.0622 L42.2011,18.0622 Z M42.2009,34.1184 L42.2009,41.9394 L34.5049,41.9394 L34.5049,40.4394 L39.6389,40.4384 L35.3455224,36.1439825 L36.4062776,35.0834175 L40.6999,39.3784 L40.7009,34.1184 L42.2009,34.1184 Z M19.5,34.1812 L19.5,39.3152 L23.7948224,35.0209175 L24.8555776,36.0814825 L20.561,40.3762 L25.821,40.3772 L25.821,41.8772 L18,41.8772 L18,34.1812 L19.5,34.1812 Z" id="icono-expandir-rosa"></path>
                    </g>
                </g>
            </g>
        </svg>
    }
        <span className={`${styles.text} ${customText}`}>{ useIntl().formatMessage({ id: 'full.width' })}</span>
    </div>
)
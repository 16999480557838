import React from 'react'
import styles from './Navigate.module.css'
import { useIntl } from 'react-intl'
import arrow from '../../assets/arrow.svg'

export const Navigate = React.memo(({indicator, showText = false, handleClick}) => {
    const intl = useIntl()

    return(
        <div className={`flex-column content-center align-center ${styles.navigate}`} onClick={handleClick}>
            {
                ((window.innerWidth > 1200) && (indicator < 10 || showText)) &&
                <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'navigate' }) }} />
            }
            <img className={styles.arrow} src={arrow} alt={arrow} />
        </div>
    )
})
import React from 'react'
import {Accordion} from '../../components/accordion/Accordion'
import styles from './chapters.module.css'

const Chapters = () => {
    return(
        <div className={styles.fullContainer}>
            <div className={styles.container}>
                <Accordion />
            </div>
        </div>
    )
}

export default Chapters
import React from 'react';
import styles from './Navigate.module.css';
import { useIntl } from 'react-intl';
import arrow from '../../assets/arrow.svg';

export const InitialNavigation = React.memo(({ showArrow, handleClick }) => {
  const intl = useIntl();
  return (
    <div
      className={`flex-column content-center align-center indicator-move-on ${styles.navigate}`}
      onClick={handleClick}
      style={{
        bottom: 0,
      }}
    >
      <p
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id:
              window.innerWidth > 1200 ? 'start.footer' : 'start.footer.mobile',
          }),
        }}
      />
      <img
        className={styles.arrow}
        src={arrow}
        style={{
          width: showArrow ? 30 : 0,
        }}
      />
    </div>
  );
});

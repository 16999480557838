import React from 'react';
import styles from './Indicator.module.css';

export const Indicator = ({ indicatorsArray, indicator, add, remove }) => {
  const handleIndicator = React.useCallback(() => {
    let array = [];
    for (let index = 0; index <= indicatorsArray.length - 1; index++) {
      array.push(
        <div
          key={index}
          className={`${styles.indicator} ${
            indicator === indicatorsArray[index] ? styles.active : ''
          }`}
        />
      );
    }

    return array;
  }, [indicator]);

  return (
    <div
      className={`fixed flex-column content-center ${styles.container_indicator}`}
    >
      <div className={styles.arrow} onClick={remove}>
        <img
          src="/images/Recurso 5.svg"
          alt=""
          className={styles.arrow_chevron}
        />
      </div>
      {handleIndicator()}
      <div className={styles.arrow} onClick={add}>
        <img
          src="/images/Recurso 6.svg"
          alt=""
          className={styles.arrow_chevron}
        />
      </div>
    </div>
  );
};
